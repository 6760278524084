import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"
import Oilchange from "../images/oilchange1.jpg"
import Padsrotors from "../images/bmw_padsrotors.jpg"
import McClaren from "../images/mclaren1.jpg"


const MaintenancePage = () => (
  <Layout>
    <PageTitle title="Preventive Maintenance" page="PageTitleServices" />
    <Seo title="Vehicle Maintenance with 97 Automotive in MD" description="97 auto is help to help you maintain your vehicles, ensuring their longevity. " />
    <div className="content-page-wrapper">
      <p>
      
      If you own a vehicle, you should do some regular maintenance along the way. If you don't, these issues can become serious and end up costing you a lot of money. 
      Preventative maintenance on vehicles is important in order to prolong their lifespan and to spare the owner the trouble of having to buy a new vehicle 
      because the old one stopped working prematurely.
      </p>
      <h2>Maintenance Services</h2>      
      <ul>
        <li>oil changes - regular oil changes prevent excessive engine wear</li>
        <li>tire rotations &amp; alignments - keeping your tires balanced and properly aligned can improve your fuel efficiency and extend the life of your tires (and drivetrain).</li>
        <li>changing the brakes - check your brakes at least once a year, and more often if you frequently drive in stop-and-go traffic or in areas with a lot of hills</li>
      </ul>
      <h2>Key Benefits of Preventive Maintenance</h2>
      <ul>
        <li>Prolong Your Vehicle's Life</li>
        <li>Allow for Top Performance</li>
        <li>Higher Resale Value</li>
        <li>Save on Gas</li>
      </ul>
      
          </div>     
          <h2 className="text-center">Recent Work</h2>
      <div className="container-fluid img-row">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-4">
            <img src={Oilchange} alt="Oil Changes" className="img-fluid" />
          </div>
          <div className="col-12 col-md-4">
            <img src={Padsrotors} alt="New pads and rotors on a BMW M3" className="img-fluid" />
          </div>
          <div className="col-12 col-md-4">
            <img src={McClaren} alt="McLaren in for service" className="img-fluid" />
          </div>
        </div>
      </div>
  </Layout>
)

export default MaintenancePage
