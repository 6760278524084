import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Brands from "../images/auto_logos1.png"
import Testis from "../components/testis";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarBattery, faWandMagicSparkles, faTruckMonster, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const IndexPage = () => (
  <Layout>
    <Hero />
    <Seo title="97 Automotive | Auto repair in Millersville MD" description="97 Automotive assesses and repairs vehicles in MD, especially Honda, Ford, and Jeeps." />
    <div className="services-wrapper">
      <div className="service-card">
        <div className="fa-wrapper">
          <FontAwesomeIcon icon={faWandMagicSparkles} />
        </div>
        
        <h2>Automotive<br /> Repairs</h2>
        <p>
        We are experts at diagnosing and repairing automotive vehicles. We handle everything from the basic check engine light 
        issues to complex rebuilds. We're here to help revive your car.   
        </p>
      </div>      
      <div className="service-card">
        <div className="fa-wrapper">
          <FontAwesomeIcon icon={faTruckMonster} />
        </div>
        <h2>Performance<br /> Enhancements</h2>
        <p>
        Ready to enhance the performance of your sports car or lift your SUV? 97 Automotive can install the latest technology, tires, lift 
        or level your vehicle, or any other enhancement you're looking for.
        </p>
      </div>      
      <div className="service-card">
        <div className="fa-wrapper">
          <FontAwesomeIcon icon={faCarBattery} />
        </div>
        <h2>Preventive<br /> Maintenance</h2>
        <p>
        In addition to repairs and enhancements, 97 Automotive is dedicated to partnering with you to ensure the 
        longevity and performance of your vehicle. 
        </p>
      </div>
      <div className="service-card">
        <div className="fa-wrapper">
          <FontAwesomeIcon icon={faClipboardCheck} />
        </div>
        <h2>MD State<br /> Inspections</h2>
        <p>
        97 Automotive is an official inspection station (9601) for Maryland state inspections. Stop by today to get your vehicle inspected.   
        </p>
      </div>
    </div>  
    <div className="home-logos text-center">
    <img src={Brands} alt="Some brands we focus on" className="img-fluid" />
      </div>      
    <div className="container-fluid home-testis">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
          <div className="testis-wrapper">
          <Testis />
          </div>
        
        </div>
        <div className="col-12 col-md-6 col-lg-5 col-xl-4 collage">
          <div className="get-social">
            <h3>Follow us on Facebook &amp; Instagram!</h3>
            <p>
            <a href="https://www.facebook.com/97Auto/" className="social m-2" rel="noreferrer noopener" target="_blank" aria-label="facebook">
                <FontAwesomeIcon icon={faFacebook} /></a>   
                <a href="https://www.instagram.com/97_automotive/" className="social m-2" rel="noreferrer noopener" target="_blank" aria-label="instagram">
                <FontAwesomeIcon icon={faInstagram} /></a>
            </p>
            
          </div>
        </div>
      
      </div>
      
    </div>
  </Layout>
)

export default IndexPage
