import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"
import CatShield from "../images/cat_shield1.jpg"
import GSRHarness from "../images/gsr_harness_manifold.jpg"
import Jeep1 from "../images/jeep1.jpg"

const PerformancePage = () => (
  <Layout>
    <PageTitle title="Automotive Performance" page="PageTitleServices" />
    <Seo title="Automotive services from 97 Automotive in MD" description="We specialize in automotive services, including performance modifications, for vehicles in Maryland. " />
    <div className="content-page-wrapper">
      <p>
      Are you looking to boost your car's speed or lift up your Jeep or Tundra? Maybe you have a purpose-built show car and you want a 
      supercharger system installed correctly, or just some bolt-ons installed with exquisite detail. No matter what the job is, 
      97 Auto is the right choice for all of your performance modification needs. We specialize 
      in performance and are performance automotive enthusiasts in our off time. We take the extra time to plan a 
      build and cover all points of interest to our customers on a completely individual basis. 
      </p>      
      <p>
      If you're behind the wheel of a Jeep, truck, or SUV and feel the urge for an off-road excursion, stop by to talk about options. 
      Our team knows lift installations inside and out and have earn some badges ourselves. Do some mods and then hit some of the amazing trails 
      in Maryland, PA, and West Virginia.
      </p>
      <h2 className="text-center">Recent Work</h2>
      <div className="container-fluid img-row">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-4">
            <img src={CatShield} alt="Cat Shield on a Tundra" className="img-fluid" />
          </div>
          <div className="col-12 col-md-4">
            <img src={GSRHarness} alt="Harness cleaning and manifold install on GSR" className="img-fluid" />
          </div>
          <div className="col-12 col-md-4">
            <img src={Jeep1} alt="Lift kit, wheels/tires, and painted fender flares" className="img-fluid" />
          </div>
        </div>
      </div>
      
    </div>        
  </Layout>
)

export default PerformancePage
