import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'


export default function Testis() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      <div className="testi" key="1">
        <p>
        <FontAwesomeIcon icon={faQuoteLeft} /> 97 Auto took great care of my baby. Pricing is always fair and quality is top knotch. <FontAwesomeIcon icon={faQuoteRight} />
        <br /><br />- CHRIS <br />2022 CORVETTE</p>
      </div>
      <div className="testi" key="2">
      <p>
        <FontAwesomeIcon icon={faQuoteLeft} /> Aaron and his team displayed an amazing understanding of my Ford Explorer and were able to get it back to almost new.  <FontAwesomeIcon icon={faQuoteRight} />
        <br /><br />- STEVE  <br />FORD EXPLORER
        </p>
      </div>
      <div className="testi" key="3">
      <p>
        <FontAwesomeIcon icon={faQuoteLeft} /> 97 Auto enhanced my Yukon with a new grill, tires, and a levelling kit. It looks and rides smooth! <FontAwesomeIcon icon={faQuoteRight} />
        <br /><br />- FRANK ADEE  <br />BOB | YUKON XL
        </p>
      </div>
    </Slider>
  );
}