import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const RepairsPage = () => (
  <Layout>
    <PageTitle title="Auto Repairs" page="AutoRepairs" />
    <Seo title="Auto Repair Services from 97 Automotive in MD" description="97 Auto offers major and minor automotive repair services in MD. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>97 Auto offers a wide range of automotive repair services. We've listed some below, but we can handle 
                any repair services needed.  </p>
            <h2>Auto Repair Services</h2>
            <ul>
              <li>transmission repair</li>
              <li>check engine light and advanced diagnostics</li>
              <li>brake service and repair</li>
              <li>air conditioning</li>
              <li>cooling system maintenance and repair</li>
              <li>oil change and all scheduled maintenance</li>
              <li>engine repair</li>
            </ul>
            <h2>Brake Services &amp; Repairs</h2>
            <p>
            Braking systems can be a matter of life or death, so regular inspections 
            should be performed at least twice a year, and the brake service schedule should be strictly followed. 
            Any brake work should be performed by certified master mechanics, such as those at 97 Auto. 
            </p>
            <h2>Check Engine Lights</h2>
            <p>
            If your check engine light turns on, stop by and we'll check your on-board computer and explain our findings. 
            It may just mean it's time for maintenance, but it could also be alerting you to an unsafe condition 
            or something that if neglected could lead to expensive repairs. The auto computer is connected to an amazing number of sensors, 
            and can spot problems in the ignition system, brakes, transmission, catalytic converter, and more.
            </p>
            <h2>Engine Repair</h2>
            <p>
            Engines used to be pretty simple: battery, alternator, ignition coil, distributor, and spark plugs. Now with the more 
            complex computer systems, you need to rely on our experts to dignose and repair your engine.      
            </p>
            <h2>Heating &amp; Cooling</h2>
            <p>
            Heating and A/C should be inspected seasonally to keep your automobile comfortable. Unfortunately, it's usually too late when you 
            find out it's not working. Drop by 97 Auto to get your heating and/or cooling fixed up.     
            </p>
       </div>       
        
    </div>        
  </Layout>
)

export default RepairsPage
