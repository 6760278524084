import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const ServicesPage = () => (
  <Layout>
    <PageTitle title="Services" page="AutoRepairs" />
    <Seo title="Auto Repair Services from 97 Automotive in MD" description="97 Auto offers major and minor automotive repair services in MD. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>Are you looking for an affordable auto repair shop in Anne Arundel County that you can trust for your vehicle? We've been providing repairs for over 20 years with guaranteed satisfaction. 
                For newer vehicles we use OEM parts and our maintenance services will keep your warranty valid when possible. We can handle all repairs, whether they 
                are major or minor. </p>
            <h2>Auto Repair Services</h2>
            <ul>
              <li>transmission repair</li>
              <li>check engine light and advanced diagnostics</li>
              <li>brake service and repair</li>
              <li>air conditioning</li>
              <li>cooling system maintenance and repair</li>clutch repair
              <li>oil change and all scheduled maintenance</li>
              <li>engine repair</li>
            </ul>
            <p>
            <br /><Link to="/repairs" className="btn btn-dark">Auto Repair Services</Link><br />
            </p>
            <h2>Preventive Maintenance</h2>
            <p>
            Preventive maintenance refers to the regularly performed tasks on a vehicle to lessen the likelihood of it failing. The main services include oil 
            changes, tire rotations &amp; alignment, and brake systems checks. It's a proactive approach taken while the vehicle is still working well.    
            <br /><br /><Link to="/maintenance" className="btn btn-dark">Maintenance Services</Link><br />
            </p>
            <h2>Performance Services</h2>
            <p>
            Are you looking to boost your car's speed or lift up your Jeep or Tundra? Maybe you have a purpose-built show car and you want a 
            supercharger system installed correctly, or just some bolt-ons installed for some eye candy. 
            97 Auto is the right choice for all of your performance modification needs. 
            <br /><br /><Link to="/performance" className="btn btn-dark">Performance Services</Link><br />
            </p>            
            <h2>Wheels &amp; Tires</h2>
            <p>
              Wheels &amp; tires are one of the biggest ways to distinguish your vehicle from the next one. It's the best way to take a stock 
              vehicle and give it some life and uniqueness. Whether you're looking for slick, racing wheels, or some big off-roading wheels, we've 
              got you covered at 97 Auto. 
            </p>
            <h2>MD State Inspections</h2>
            <p>
            To obtain an inspection certification, you must have your vehicle inspected at a licensed vehicle safety inspection station in Maryland, which we happen to be! 
            We are currently authorized to inspect Class A vehicles, so feel free to contact us or drop on by for your inspection.
            </p>
            <p>
              To learn more about inspections, you can visit the <a href="https://mva.maryland.gov/about-mva/Pages/info/58000ASE/58000-01T.aspx">MVA Website</a>.
            </p>
       </div>       
        
    </div>        
  </Layout>
)

export default ServicesPage
