import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const AboutPage = () => (
  <Layout>
    <PageTitle title="About 97 Automotive" page="PageTitleAbout" />
    <Seo title="Learn more about 97 Automotive in MD" description="Learn more about 97 Auto and our ability to repair, enhance, and/or maintain your vehicles. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            Family owned and operated in Anne Arundel County, MD, 97 Automotive Repair was formed to give you a dealer like experience 
            for you and your car, while saving you money. You can count on us to do the job right and save you money. 
            We've been in business for over 20 years, have the latest in diagnostic equipment, 
            and employ ASME certified master mechanics. 
            </p>
            <p>97 Automotive specializes in automotive enhancements, repairs, and maintenance. 97 Automotive is certified 
              to work on a variety of the best brands in the world. Some services include:</p>
                <ul>
                    <li>Tire replacement and installation</li>
                    <li>Brake and Springs enhancements and replacement</li>
                    <li>Engine and transmission analysis, tuning, and replacement</li>
                    <li>Oil changes and tire rotations</li>
                                  
                </ul>
            
            
        </div> 
    </div>        
  </Layout>
)

export default AboutPage
