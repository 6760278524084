import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMap, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const ContactPage = () => (
  <Layout>
    <PageTitle title="Contact 97 Automotive in Millersville MD" page="PageTitleContact" />
    <Seo title="Contact 97 Automotive, located in Maryland" description="Contact us to schedule your automotive performance enhancements and/or repairs. " />
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p>
            97 Automotive is a full-service automotive repair and performance enhancement shop, located in Millersville, MD.  
            </p>  

            <p>
            <FontAwesomeIcon icon={faPhone} /> (443) 688-6721 or you can call/text (443) 790-0928<br />
            <FontAwesomeIcon icon={faMap} /> 8328 Veterans Hwy, Ste J<br />
Millersville, Maryland <Link to="https://www.google.com/maps/place/8328+Veterans+Hwy,+Millersville,+MD+21108/@39.108692,-76.630351,15z/data=!4m5!3m4!1s0x89b7fb48888c1a61:0x9790a056b2fb1d3c!8m2!3d39.1086924!4d-76.6303507?hl=en-US" target="_blank" >View Map</Link><br />
            <FontAwesomeIcon icon={faEnvelope} /> info@97automotive.com<br />                                
            </p> 
        </div> 
        <div>
            <h3>Conveniently located in Anne Arundel County, MD, near:</h3>
            <ul className="cities">
            <li>Annapolis, MD</li>
            <li>Baltimore, MD</li>            
            <li>Crofton, MD</li>
            <li>Crownsville, MD</li>
            <li>Glen Burnie, MD</li> 
            <li>Millersville, MD</li>            
            <li>Odenton, MD</li>
            <li>Pasadena, MD</li>
            <li>Severn, MD</li>
            <li>Severna Park, MD</li>
            </ul>
        </div>
    </div>        
  </Layout>
)

export default ContactPage
